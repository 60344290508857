'use client';
import dynamic from 'next/dynamic';
import { ComponentType, ImgHTMLAttributes } from 'react';

const iconMapsData = [
  ['envelope', dynamic(() => import('public/static/website/svg/icon-envelope.svg'))],
  ['padlock', dynamic(() => import('public/static/website/svg/icon-padlock.svg'))],
  ['person', dynamic(() => import('public/static/website/svg/icon-person.svg'))],
  ['checkmark', dynamic(() => import('public/static/website/svg/icon-checked.svg'))],
  ['star', dynamic(() => import('public/static/website/svg/icon-star.svg'))],
  ['star-hollow', dynamic(() => import('public/static/website/svg/icon-star-hollow.svg'))],
  ['check-filled', dynamic(() => import('public/static/website/svg/icon-check-filled.svg'))],
  ['forbidden', dynamic(() => import('public/static/website/svg/icon-forbidden.svg'))],
] as const;

export type IconType = (typeof iconMapsData)[number][0];

export const iconsMap = new Map(iconMapsData) as Map<IconType, ComponentType<ImgHTMLAttributes<SVGElement>>>;

export const Icon = ({ className, icon, color, size }: { className?: string; icon: IconType, color?: string, size: string | number }) => {
  const IconComponent = iconsMap.get(icon);
  if (!IconComponent) {
    throw new Error(`Icon ${icon} not found`);
  }
  return <IconComponent className={className} style={{ color }} width={size} height={size} />;
}

export const Icons = ({ color, size }) => {
  return (
    <div className="grid grid-cols-4 grid-rows-1 gap-2 w-full">
      {[...iconsMap.keys()].map((icon) => (
        <Icon key={icon} icon={icon} color={color} size={size} />
      ))}
    </div>
  );
}